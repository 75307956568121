import React from "react";
import Lobby from "../../views/Lobby";
import Layout from "../../components/Layout/Layout";
import SignUp from "../../views/Home/SignUp";
const Community = () => {
  return (
    <>
      <div className="bg-bgBlack">
        <Layout>
          <Lobby />
          <div id="Signup">
          <SignUp />
          </div>
        </Layout>
      </div>
    </>
  );
};

export default Community;
