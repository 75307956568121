import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../components/Layout/context";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import remarkGemoji from "remark-gemoji";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../../components/SEO";
import slugify from "slugify";
import { useLocation } from "@reach/router";
import removeMd from "remove-markdown";
import { FaRegStar, FaRegCheckCircle, FaChevronDown } from "react-icons/fa"

const isBrowser = typeof window !== "undefined";

const Lobby = () => {
  const location = useLocation();
  const slug = location.pathname
    .replace("/c/", "")
    .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");

  const { lan, setLang } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFinished, setIsFinished] = useState(false)
  const [interest, setInterest] = useState("")
  const INTERESRTING_DEFAULT = "Interesting ?"
  const INTERESRTING_INTERESED = "interested"
  const INTERESRTING_GOING = "going"
  const INTERESRTING_NOT = "not interested"

  useEffect(() => {
    const languagekey = localStorage.getItem("lankey")
    if (languagekey === null) {
      localStorage.setItem("lankey", "en")
      setLang("en")
    }
    const interestKey = localStorage.getItem("interest")
    if (interestKey === null) {
      setInterest(INTERESRTING_DEFAULT)
    } else {
      setInterest(interestKey)
    }

    if (isBrowser && window.analytics) {
      window.analytics.track("LobbyPageSegment", {
        community: slug,
        isLobbyClosed: isDisabled,
        interest: interestKey,
      })
    }

    if (window.gtag) {
      window.gtag("event", "lobby_page", {
        label: "window.gtag",
        value: slug,
        isLobbyClosed: isDisabled,
      });
    }

  }, [isDisabled])

  function handleOnClick(e) {
    e.preventDefault();
    if (isBrowser && window.analytics) {
      window.analytics.track("EnterEventLobby");
    }
    if (window.gtag) {
      window.gtag("event", "enter_event_lobby", {
        label: "window.gtag",
        value: slug,
      });
    }
    window.location.href = filteredLobby[0]?.node?.app_url;
  }

  function handleInterest(e) {
    localStorage.setItem("interest", e.target.value)
    setInterest(e.target.value)
    if (isBrowser && window.analytics) {
      window.analytics.track("Interesting", {
        interest: e.target.value,
        isLobbyClosed: isDisabled,
      })
    }
  }

  const data = useStaticQuery(graphql`
    query {
      allStrapiLobby {
        edges {
          node {
            locale
            event_name
            app_url
            event_description
            blue_button_title
            blue_button_url
            orange_button_title
            orange_button_url
            feedback_url
            date_time_string
            slug
            image {
              url
              height
              width
            }
          }
        }
      }
    }
  `);

  const lobbies = data.allStrapiLobby?.edges;
  const filteredData = lobbies.filter(({ node }) => node.locale.includes(lan));
  const filteredLobby = filteredData.filter(({ node }) =>
    slugify(node.slug, {
      lower: true,
    }).includes(slug)
  );
  const eventDate = new Date(
    `${filteredLobby[0]?.node?.date_time_string}`.replace(/-/g, "/")
  );

  const eventDateString = filteredLobby[0]?.node?.date_time_string;
  const datePlus24hr = new Date(
    new Date(`${filteredLobby[0]?.node?.date_time_string}`.replace(/-/g, "/")).setTime(eventDate.getTime() + 60 * 60 * 1000 * 24)
  ).getTime()
  
  useEffect(() => {
    setInterval(() => {
      const countDownDate = eventDate.getTime();
      if (countDownDate) {
        // Get today's date and time
        const now = new Date().getTime();
        
        if (now > datePlus24hr) {
          setIsFinished(true)
          return
        }

        // Find the distance between now and the count down date
        const distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const op = distance > 0 ? Math.floor : Math.ceil;
        const days = op(distance / (1000 * 60 * 60 * 24));
        const hours = op((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = op((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = op((distance % (1000 * 60)) / 1000);

        if (distance >= 0) {
          document.getElementById("countdown").innerHTML = `${
            days > 0 ? days : "0"
          } d ${hours > 0 ? hours : "0"} h 
          ${minutes > 0 ? minutes : "0"} m ${seconds > 0 ? seconds : "0"} s`;
        }
        if (distance < 0) {
          setIsDisabled(false);
        }
      }
    }, 1000);
  });

  const dateTimeLocalOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };  

  const metaTitle = `Happen Space | ${eventDateString && eventDateString
    .split(" ")
    .slice(0, eventDateString.split(" ").length - 1)
    .join(" ")} - ${filteredLobby[0]?.node?.event_name} `;

  const metaDescription = `${eventDateString} - ${removeMd(
    filteredLobby[0]?.node?.event_description
  )}`;

  return (
    <div className="main-container text-white md:text-left text-center md:lg:pb-[80px] pb-2 px-4 px-0 max-w-6xl mx-auto">
      <SEO
        title={`Happen Space | ${filteredLobby[0]?.node?.event_name}`}
        description={`${filteredLobby[0]?.node?.event_description}`}
        metaTitle={metaTitle}
        customMetaDescription={metaDescription}
        customMetaImage={filteredLobby[0]?.node?.image}
        pathname={location.pathname}
      />
      <h1 className="pt-8 text-2xl font-semibold text-white md:text-5xl">
        {filteredLobby[0]?.node?.event_name}
      </h1>
      <p className="py-5 mb-16">
        {filteredLobby[0]?.node?.date_time_string} (
        {eventDate.toLocaleString("default", dateTimeLocalOptions)})
      </p>
      {/* Not started */}
      {isDisabled && !!!isFinished && (
        <div className="before-event">
          <div className="flex justify-center ">
            <button
              className="px-[70px] py-[20px] rounded-[7px] text-lg font-semibold text-white capitalize disabled-btn"
              disabled
            >
              Enter online event
            </button>
          </div>
          {interest !== INTERESRTING_DEFAULT &&
          interest !== INTERESRTING_NOT ? (
            <div className="flex justify-center ">
              <button className="pt-5 relative">
                <select
                  className="rounded-[7px] text-sm font-semibold text-white bg-[#362F38] cursor-pointer pl-3 pr-10 py-[10px] appearance-none hide-selection-border"
                  value={interest}
                  onChange={(e) => handleInterest(e)}
                >
                  <option disabled hidden>
                    {INTERESRTING_DEFAULT}
                  </option>
                  <option value={INTERESRTING_INTERESED}>Interested</option>
                  <option value={INTERESRTING_GOING}>Going</option>
                  <option value={INTERESRTING_NOT}>Not Interested</option>
                </select>
                <span className="absolute top-[32px] right-[20px] pointer-events-none">
                  <FaChevronDown />
                </span>
              </button>
            </div>
          ) : (
            <div
              className="btn-group flex justify-center pt-5"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="submit"
                className="mr-3 relative rounded-[7px] text-sm font-semibold text-white bg-[#362F38] cursor-pointer px-[12px] py-[10px]"
                value={INTERESRTING_INTERESED}
                onClick={handleInterest}
              >
                <FaRegStar className="icon-fa" />
                Interested
              </button>
              <button
                type="button"
                className="mr-3 relative rounded-[7px] text-sm font-semibold text-white bg-[#362F38] cursor-pointer px-[12px] py-[10px]"
                value={INTERESRTING_GOING}
                onClick={handleInterest}
              >
                <FaRegCheckCircle className="icon-fa" />
                Going
              </button>
            </div>
          )}
          <div className="flex justify-center ">
            <p className="flex py-5">Online Event opens in...</p>
          </div>
          <div
            className="border blog-detail border-[#A8A5A9] rounded-lg px-10 mb-16 py-16 counting text-center md:"
            id="countdown"
          ></div>{" "}
        </div>
      )}{" "}
      {/* Started and not finish by 24 hr. */}
      {!!!isDisabled && !!!isFinished && (
        <div className="flex justify-center ">
          <button
            className="px-[70px] py-[20px] rounded-[7px] text-lg font-semibold text-white green-enter-btn capitalize"
            id="greenEnterEventButton"
            onClick={handleOnClick}
          >
            Enter online event
          </button>
        </div>
      )}
      {/* Started and finished by 24 hr. */}
      {isFinished && (
        <div className="flex justify-center ">
          <button
            className="px-[70px] py-[20px] rounded-[7px] text-lg font-semibold text-white capitalize disabled-btn"
            disabled
          >
            Event Ended
          </button>
        </div>
      )}
      {filteredLobby[0]?.node?.image && (
        <div className="flex items-center justify-center rounded-md pt-5">
          <img src={filteredLobby[0]?.node?.image.url} className="rounded-md" />
        </div>
      )}
      <div className="blog-detail p-6 flex flex-col justify-between gap-y-[31px] bg-white rounded-lg text-black text-lg mb-5 mt-10 lobby ">
        <ReactMarkdown
          children={filteredLobby[0]?.node?.event_description}
          remarkPlugins={[remarkGfm, remarkMath, remarkGemoji]}
          rehypePlugins={[rehypeRaw]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "")
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, "")}
                  style={dark}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              )
            },
          }}
        />
      </div>
      <p className="pb-5">
        <strong>Note</strong>: event will happen on a development version of{" "}
        <a href="https://happen.space">Happen</a>, a new online event platform.
        <br />
        It's a work in progress but got{" "}
        <a
          href={filteredLobby[0]?.node?.feedback_url}
          target="_blank"
          className="font-semibold text-secondary"
        >
          feedback? Let us know!
        </a>
      </p>
      {/* {filteredLobby[0]?.node?.orange_button_title != null || filteredLobby[0]?.node?.blue_button_title != null ? (
        <h4 className="pb-5 mt-5">To hear about future events</h4>
      ): ""}  */}
      {filteredLobby[0]?.node?.blue_button_title != null &&
      filteredLobby[0]?.node?.blue_button_title != "" ? (
        <a
          className={`text-sm text-white font-semibold capitalize bg-[#69a1fe] rounded-lg cursor-pointer px-3 py-[13px] mr-5`}
          target="_blank"
          href={filteredLobby[0]?.node?.blue_button_url}
        >
          {filteredLobby[0]?.node?.blue_button_title}
        </a>
      ) : (
        ""
      )}
      {filteredLobby[0]?.node?.orange_button_title != null &&
      filteredLobby[0]?.node?.orange_button_title != "" ? (
        <a
          role="button"
          className="text-sm text-white font-semibold capitalize bg-[#fc9066] rounded-lg cursor-pointer px-3 py-[13px]"
          target="_blank"
          href={filteredLobby[0]?.node?.orange_button_url}
        >
          {filteredLobby[0]?.node?.orange_button_title}
        </a>
      ) : (
        ""
      )}
    </div>
  )
};
export default Lobby;
